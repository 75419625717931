import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import API_STATES from '@/constants/StateConstants';

const http = axios.create({
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

/*
Watches auth token state changes to keep the request header in sync
*/

http.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    }
    return config;
});

function getErrorMessage(error) {
    if (error.response?.data?.code) {
        return error.response.data.code;
    }
    return error.toString();
}
export async function getCategories() {
    try {
        const response = await http.get(`/product/category/`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}

export async function submitChangedCategories(event_name, event_type, old_category, new_category, scheduled_date) {
    try {
        const response = await http.post(`product/categories_change`, {
            event_name,
            event_type,
            old_category,
            new_category,
            scheduled_date
        })
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}

export async function getConsolidatedCategories() {
    try {
        const response = await http.get(`product/categories_change`,{
            params: {
                eventType : 'consolidate'
            },
        })
        return { status: API_STATES.success, data: response.data };
    } catch(error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };

    }
}
export async function getSplitUpCategories() {
    try {
        const response = await http.get(`product/categories_change`,{
            params: {
                eventType : 'split_up'
            },
        })
        return { status: API_STATES.success, data: response.data };
    } catch(error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };

    }
}
export async function getSuperCategories() {
    try {
        const response = await http.get(`/product/supercategory/`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}
export async function getDepartments() {
    try {
        const response = await http.get(`/product/department/`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}
export async function getTotalStore() {
    try {
        const response = await http.get(`/product/totalstore/`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}

export async function getSubscriptionTypes() {
    try {
        const response = await http.get(`/product/subscriptionTypes`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}

export async function getOmniCategories() {
    try {
        const response = await http.get(`/product/omni_category/`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}

export async function getOmnishopperCategories() {
    try {
        const response = await http.get(`/product/omni_shopper_category/`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}

export async function getAllSubscriptions() {
    try {
        const response = await http.get(`/product/subscription`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getAllProductBundles() {
    try {
        const response = await http.get(`/product/product_bundles`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getAllReports() {
    try {
        const response = await http.get(`/product/reports`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getMatchingBrands(search) {
    try {
        if (search === '') {
            throw Error('Search term should not be empty');
        }
        const response = await http.get('/product/brands/', { params: { search } });
        if (response.data.error !== undefined) {
            throw Error(response.data.error.toString());
        } else {
            return { status: API_STATES.success, data: response.data };
        }
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}

export async function brandExists(search) {
    try {
        if (search === '') {
            throw Error('Search term should not be empty');
        }
        const response = await http.get('/product/brands/', {
            params: {
                search,
                exists: true,
            },
        });
        if (response.data.error !== undefined) {
            throw Error(response.data.error.toString());
        } else {
            return { status: API_STATES.success, data: response.data };
        }
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCategoriesForBrand(brand) {
    try {
        if (brand === '') {
            throw Error('brand search term should not be empty');
        }

        // /product/category?brand=...
        const response = await http.get('/product/category/', { params: { brand } });
        if (response.data.error !== undefined) {
            throw Error(response.data.error.toString());
        } else {
            return { status: API_STATES.success, data: response.data };
        }
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCategoriesWithKeys() {
    try {
        // /product/category?keys=...
        const response = await http.get('/product/category/', { params: { keys: true } });
        if (response.data.error !== undefined) {
            throw Error(response.data.error.toString());
        } else {
            return { status: API_STATES.success, data: response.data };
        }
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getOmniCategoriesWithKeys() {
    try {
        // /product/omni_category?keys=...
        const response = await http.get('/product/omni_category/', { params: { keys: true } });
        if (response.data.error !== undefined) {
            throw Error(response.data.error.toString());
        } else {
            return { status: API_STATES.success, data: response.data };
        }
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanyCategoryCount(nsCompanyId) {
    try {
        const response = await http.get(`/product/purchasedCategoryCount/${nsCompanyId}`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanyOmniCategoryCount(nsCompanyId) {
    try {
        const response = await http.get(`/product/purchasedOmniCategoryCount/${nsCompanyId}`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanyOmnishopperCategoryCount(nsCompanyId) {
    try {
        const response = await http.get(`/product/purchasedOmniShopperCategoryCount/${nsCompanyId}`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getAllBundles() {
    try {
        const response = await http.get(`/product/bundle/`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function deleteBundle(bundlesku) {
    try {
        const response = await http.delete(`/product/bundle/${bundlesku}`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getBundleDetails(bundlesku) {
    try {
        const response = await http.get(`/product/bundle/${bundlesku}`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function updateBundleTitle(bundleSku, newTitle) {
    try {
        const response = await http.put(`/product/bundle/${bundleSku}`, { newTitle });
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

// expected response {id, sku, title, type}
export async function getChildSkuDetails(childSku) {
    try {
        const response = await http.get(`/product/catalog_item?sku=${childSku}`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function deleteChildSku(bundleSku, childSku) {
    try {
        const response = await http.delete(`/product/bundle/${bundleSku}/child_product/${childSku}`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function addChildSkuDetails(newChildSku, bundleSku) {
    try {
        const response = await http.put(`/product/bundle/${bundleSku}`, { newChildSku });
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function createBundle(bundleSku, bundleTitle, productSkuArray) {
    try {
        const response = await http.post(`/product/bundle`, { bundleSku, bundleTitle, productSkuArray });
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function saveExpiryDate(nsContractItemId, expiryDate, nsCompanyId) {
    try {
        const response = await http.put(`/product/subscription`, { nsContractItemId, expiryDate, nsCompanyId });
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getFoldersForMarketMaps(marketMapTpe) {
    try {
        const response = await http.get(`/marketmaps/folder/${marketMapTpe}/getNames`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getFilesForMarketMaps(parentFolderId) {
    try {
        const response = await http.get(`/marketmaps/file/${parentFolderId}/getNames`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function renameMarketMapsFile(id,newFileName) {
    try {
        const response = await http.put(`/marketmaps/file/${id}/rename`,{newFileName});
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function renameMarketMapsFolder(id,newFolderName) {
    try {
        const response = await http.put(`/marketmaps/folder/${id}/rename`,{newFolderName});
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function deleteMarketMapsFolder(id) {
    try {
        const response = await http.delete(`/marketmaps/folder/${id}/delete`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function deleteMarketMapsFile(id,folderName) {
    try {
        const response = await http.post(`/marketmaps/file/${id}/delete`,{folderName});
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function addMarketMapsFolder(newFolderName, marketMapType) {
    try {
        const response = await http.post(`/marketmaps/folder/add`,{newFolderName, marketMapType});
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function addMarketMapsFile(foldername, marketMapType,newFileName,file,folderId,fileType) {
    try {
        const {data} = await http.post(`/marketmaps/files/add`,{newFileName, marketMapType,foldername,folderId,fileType});
        const onUploadProgress=(e) => {
            console.log(e)
        }
        const response=await axios.put(data.downloadUrl, file,
            {
                onUploadProgress,
                headers: {
                    'Content-Type': fileType,
                },
                // headers: {
                //     'Content-Type': 'application/vnd.openxmlformats',
                // },
            });
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

