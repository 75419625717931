import { setOmnishopperCategories } from '@/services/api/company.service';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/CompanyConfig/CompanyConfig.module.scss';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';

export function EditCompanyOshCategories({
    allOshCategories,
    nsCompanyId,
    companyOshCategories,
    maxAllowedCategories,
    onSuccess = () => {},
    onCancel,
    disabled = false,
}) {
    const [updateCategoryApiState, setUpdateCategoryApiState] = useState(API_STATES.none);
    const [updateCategoryApiError, setUpdateCategoryApiError] = useState('');

    const [selectedCategories, setSelectedCategories] = useState([]);

    useEffect(() => {
        setSelectedCategories(companyOshCategories);
    }, [companyOshCategories]);

    function isUpdateCategoriesEnabled() {
        const changedCategories = R.symmetricDifference(selectedCategories, companyOshCategories);
        return (
            !disabled &&
            maxAllowedCategories >= selectedCategories.length &&
            changedCategories.length &&
            updateCategoryApiState === API_STATES.none
        );
    }

    async function updateCategories() {
        if (!isUpdateCategoriesEnabled()) {
            return;
        }
        setUpdateCategoryApiState(API_STATES.loading);
        const categories = selectedCategories.map((category) => category.value);
        const response = await setOmnishopperCategories(nsCompanyId, categories);
        if (response.status === API_STATES.success) {
            setUpdateCategoryApiState(API_STATES.success);
            // leaving some time for user to see success message or error
            setTimeout(() => {
                onSuccess();
                setUpdateCategoryApiState(API_STATES.none);
            }, 2000);
        } else {
            handleApiError(response.error, setUpdateCategoryApiState, setUpdateCategoryApiError);
            setTimeout(() => setUpdateCategoryApiState(API_STATES.none), 3000);
        }
    }

    const onSelectionChange = (selectedCategories) => {
        if (
            selectedCategories?.some((category) => category?.value === 'all') &&
            selectedCategories.length <= maxAllowedCategories
        ) {
            setSelectedCategories(allOshCategories);
        } else if (selectedCategories.length <= maxAllowedCategories) {
            setSelectedCategories(selectedCategories);
        }
    };

    return (
        <>
            <p data-test="omni-category-count">
                {selectedCategories.length} of {maxAllowedCategories ?? '(unknown)'} Selected
            </p>
            <div className={styles.multiSelectInput} data-test="category-list">
                <Select
                    classNamePrefix={'react-select'}
                    options={
                        maxAllowedCategories >= allOshCategories?.length
                            ? [{ label: 'All', value: 'all' }, ...allOshCategories]
                            : allOshCategories
                    }
                    closeMenuOnSelect={false}
                    isMulti={true}
                    onChange={onSelectionChange}
                    value={selectedCategories}
                    placeholder="Please select CATEGORIES"
                    isDisabled={disabled}
                />
            </div>
            {maxAllowedCategories === undefined || maxAllowedCategories == null ? (
                <div className={generalStyles.errorMessage}>Failed to fetch omnishopper categories count limit</div>
            ) : selectedCategories.length > maxAllowedCategories ? (
                <div className={generalStyles.errorMessage}>maximum categories limit exceeded</div>
            ) : (
                selectedCategories.length === maxAllowedCategories && (
                    <div className={generalStyles.statusMessage}>maximum categories limit reached</div>
                )
            )}
            <div className={styles.columnar}>
                <button
                    className={generalStyles.submitButton}
                    onClick={() => onSelectionChange(allOshCategories)}
                    disabled={
                        disabled ||
                        maxAllowedCategories < allOshCategories.length ||
                        updateCategoryApiState !== API_STATES.none
                    }
                    title={
                        maxAllowedCategories < allOshCategories.length
                            ? `Insufficient credits to select all ${allOshCategories.length} omnishopper categories`
                            : ''
                    }
                    data-test="select-all-omni-categories-button"
                >
                    Select All
                </button>
                <button
                    className={generalStyles.submitButton}
                    onClick={updateCategories}
                    disabled={!isUpdateCategoriesEnabled()}
                    data-test="save-changed-categories-button"
                >
                    Save Changes
                </button>
                {updateCategoryApiState === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Processing...</span>
                ) : updateCategoryApiState === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}>Error: {updateCategoryApiError}</span>
                ) : (
                    updateCategoryApiState === API_STATES.success && (
                        <>
                            <span className={generalStyles.successMessage}> Changes Saved </span>
                        </>
                    )
                )}
                {updateCategoryApiState === API_STATES.none && (
                    <button className={generalStyles.submitButton} onClick={onCancel}>
                        Cancel
                    </button>
                )}
            </div>
        </>
    );
}
