import { useEffect, useMemo, useState } from 'react';
import { handleApiError } from '@/components/General/HandleApiError';
import { EditCompanyOshCategories } from '@/components/CompanyConfig/EditCompanyOshCategories';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/CompanyConfig/CompanyConfig.module.scss';
import { getOmnishopperCategories, getCompanyOmnishopperCategoryCount } from '@/services/api/product.service';
import { getCompanyConfig } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';

function ManageOshCategories({ nsCompanyId, className, isEditable = true, refreshTimestamp }) {
    const [companyOshCategoriesApi, setCompanyOshCategoriesApiState] = useState({
        state: API_STATES.none,
        error: null,
    });
    const [omniCategoriesApi, setOshCategoriesApi] = useState({
        state: API_STATES.none,
        error: null,
    });
    const [companyOshCategoriesCountApi, setCompanyOshCategoriesCountApi] = useState({
        state: API_STATES.none,
        error: '',
    });
    const [companyOshCategories, setCompanyOshCategories] = useState([]);
    const [allOshCategories, setAllOshCategories] = useState([]);
    const [companyOshCategoryCount, setCompanyOshCategoryCount] = useState(null);
    const [isInEditMode, setIsInEditMode] = useState(false);

    async function loadCompanyOmnishopperCategories(nsCompanyId) {
        setCompanyOshCategoriesApiState((prev) => ({ ...prev, state: API_STATES.loading }));
        let response = await getCompanyConfig(nsCompanyId);
        //transforming response data/error as per requirement
        if (response.status === API_STATES.success) {
            let companyOshCategories = response.data.omniShopperCategories ?? [];
            companyOshCategories = companyOshCategories.map((category) => {
                return { label: category, value: category };
            });
            setCompanyOshCategories(companyOshCategories);
            setCompanyOshCategoriesApiState((prev) => ({ ...prev, state: API_STATES.success }));
        } else {
            const error = response.error;
            handleApiError(
                error,
                (value) => setCompanyOshCategoriesApiState((prev) => ({ ...prev, state: value })),
                (value) => setCompanyOshCategoriesApiState((prev) => ({ ...prev, error: value })),
                `No config data for company ${nsCompanyId} found.`
            );
            setCompanyOshCategories([]);
        }
    }

    async function loadCompanyOshCategoryCount(nsCompanyId) {
        setCompanyOshCategoriesCountApi((prev) => ({ ...prev, state: API_STATES.loading }));
        let response = await getCompanyOmnishopperCategoryCount(nsCompanyId);
        if (response.status === API_STATES.success) {
            setCompanyOshCategoryCount(response.data);
            setCompanyOshCategoriesCountApi((prev) => ({ ...prev, state: API_STATES.success }));
        } else {
            setCompanyOshCategoryCount(null);
            handleApiError(
                response.error,
                (value) => setCompanyOshCategoriesCountApi((prev) => ({ ...prev, state: value })),
                (value) => setCompanyOshCategoriesCountApi((prev) => ({ ...prev, error: value }))
            );
        }
    }

    async function loadOshCategories() {
        setOshCategoriesApi((prev) => ({ ...prev, state: API_STATES.loading }));
        const response = await getOmnishopperCategories();
        if (response.status === API_STATES.success) {
            response.data = response.data ?? [];
            const categories = response.data.map(({ category }) => {
                return { label: category, value: category };
            });
            setAllOshCategories(categories);
            setOshCategoriesApi((prev) => ({ ...prev, state: API_STATES.success }));
        } else {
            handleApiError(
                response.error,
                (value) => setOshCategoriesApi((prev) => ({ ...prev, state: value })),
                (value) => setOshCategoriesApi((prev) => ({ ...prev, error: value }))
            );
            setAllOshCategories([]);
        }
    }

    useEffect(() => {
        if (isEditable) {
            loadOshCategories();
        } else {
            setAllOshCategories([]);
        }
    }, [isEditable]);

    useEffect(() => {
        loadCompanyOshCategoryCount(nsCompanyId);
    }, [nsCompanyId, refreshTimestamp]);

    useEffect(() => {
        if (companyOshCategoriesCountApi.state === API_STATES.success) {
            loadCompanyOmnishopperCategories(nsCompanyId);
        } else {
            setCompanyOshCategories([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyOshCategoriesCountApi.state]);

    const companyCategoriesList =
        companyOshCategoriesApi.state === API_STATES.loading ? (
            <p className={generalStyles.statusMessage}>Loading Company Omnishopper Categories List....</p>
        ) : companyOshCategoriesApi.state === API_STATES.error ? (
            <p className={generalStyles.errorMessage}>Error: {companyOshCategoriesApi.error}</p>
        ) : !companyOshCategories?.length ? (
            <p className={generalStyles.statusMessage}>No omnishopper categories selected</p>
        ) : (
            <ul data-test="selected-omni-category-list" className={generalStyles.tileList}>
                {companyOshCategories.map((category) => (
                    <li data-test="selected-omni-category-list-item" key={category.value}>
                        {category.label}
                    </li>
                ))}
            </ul>
        );

    const companyCategoriesElement =
        companyOshCategoriesCountApi.state === API_STATES.loading ? (
            <p className={generalStyles.statusMessage}>Loading Omnishopper Categories count limit....</p>
        ) : companyOshCategoriesCountApi.state === API_STATES.error ? (
            <p className={generalStyles.errorMessage}>{companyOshCategoriesCountApi.error}</p>
        ) : (
            <>
                <span data-test="selected-omni-category-element">{companyCategoriesList}</span>
            </>
        );

    function onCategoriesEdited() {
        loadCompanyOmnishopperCategories(nsCompanyId);
        setIsInEditMode(false);
    }

    const waitToChangeCategories = useMemo(() => {
        return (
            companyOshCategoriesApi.state !== API_STATES.success ||
            companyOshCategoriesCountApi.state !== API_STATES.success ||
            omniCategoriesApi.state !== API_STATES.success ||
            !isEditable
        );
    }, [companyOshCategoriesApi.state, companyOshCategoriesCountApi.state, omniCategoriesApi.state, isEditable]);

    return (
        <div className={className}>
            {isInEditMode ? (
                omniCategoriesApi.state === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>loading Omnishopper categories...</p>
                ) : omniCategoriesApi.state === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {omniCategoriesApi.error}</p>
                ) : (
                    <EditCompanyOshCategories
                        allOshCategories={allOshCategories}
                        nsCompanyId={nsCompanyId}
                        companyOshCategories={companyOshCategories}
                        maxAllowedCategories={companyOshCategoryCount}
                        onSuccess={onCategoriesEdited}
                        onCancel={() => setIsInEditMode(false)}
                        disabled={waitToChangeCategories}
                    />
                )
            ) : (
                <>
                    <span data-test="selected-omni-category-element">{companyCategoriesElement}</span>
                </>
            )}
            <span className={styles.columnar}>
                {isEditable && !isInEditMode && (
                    <button
                        className={generalStyles.submitButton}
                        disabled={waitToChangeCategories}
                        onClick={() => setIsInEditMode(true)}
                    >
                        Edit
                    </button>
                )}
                {companyOshCategoriesApi.state === API_STATES.success && (
                    <p>
                        {companyOshCategories.length} of {companyOshCategoryCount ?? '(unknown)'} Selected
                    </p>
                )}
            </span>
        </div>
    );
}

export default ManageOshCategories;
